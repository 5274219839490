import * as React from 'react';
import locale from '../localization';
import Layout from '../components/layout';

function Art() {
  return (
    <Layout title={locale('art')}>
      <h1>{locale('art')}</h1>
    </Layout>
  );
}

export default Art;
